<template>
  <section class="container-xxl article">
    <div class="sect-base">
      <div class="row">
        <div class="col-12 col-lg-11">
          <h2 class="h2 ff-m fw-6 mb-4 text-break">
            {{ curArticle.name }}
          </h2>
        </div>
        <div class="col-12">
          <div class="fs-b fw-4 clr-digray mb-3">
            {{ curArticle.date }}
          </div>
        </div>
        <div class="col-12">
          <img v-if="curArticle.srcDetail"
               :src="require(`@/assets/articles/${curArticle.srcDetail}`)"
               :alt="curArticle.name"
               class="article-img mb-3"
          >
        </div>
        <div class="col-12">
          <div v-for="(str, s) in curArticle.data" :key="`new-str-${s}`" v-html="str" class="fs-l fw-4 mb-3"/>
        </div>
      </div>
    </div>
    <div class="sect-base article-split">
      <div class="row">
        <div class="col-12 col-lg-12">
          <h6 class="h6 ff-m fw-7 text-uppercase text-start mb-5 pb-2">Больше новостей</h6>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        <div class="col" v-for="(article, i) in cutArticlesArray.slice(0, 4)" :key="`article-${i}`">
          <card-preview class="mb-5" :post="article"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";

import CardPreview from "@/components/AllCards/CardPreview";

export default {
  name: "New",
  components: {CardPreview},
  data() {
    return {
      curArticle: {},
      cutArticlesArray: [],
    }
  },
  computed: {
    routeCode() {
      return this.$route.params.code;
    },
    ...mapState({
      articles: state => state.articles.all,
    }),
    ...mapGetters({
      getArticleByCode: 'articles/getArticleByCode'
    }),
  },
  async mounted() {
    await this.getArticles();
    this.curArticle = await this.getArticleByCode(this.routeCode);
    await this.spliceArrayById();
  },
  methods: {
    ...mapActions({
      getArticles: 'articles/getArticles',
    }),
    spliceArrayById() {
      let self = this;
      this.articles.forEach(function (el, i) {
        if (el.id === self.curArticle.id) self.articles.splice(i, 1)
      })
      this.cutArticlesArray = self.articles;
    },
  },
  async beforeRouteUpdate(to) {
    this.curArticle = await this.getArticleByCode(to.params.code);
    await this.getArticles();
    await this.spliceArrayById();
  },
}
</script>
<style lang="scss" scoped>
.article {
  &-img {
    width: 100%;
    height: auto;
  }

  &-split {
    border-top: 1px solid #E3E3E8;
    padding-top: 30px;
  }
}
</style>